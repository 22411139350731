<template>
    <page-template>
        <div class="createModelCls">
            <el-form ref="form" :model="form" :rules="rules" label-width="150px">
                <el-form-item label="模型名称" prop="modelname">
                    <el-input v-model="form.modelname" clearable></el-input>
                </el-form-item>
                <el-form-item label="任务名称" prop="modeStep">
                    <el-select v-model="form.modeStep" clearable placeholder="请选择任务名称">
                        <el-option label="模型训练" value="mode_train"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模型类型" prop="modelType">
                    <el-radio-group v-model="form.modelType">
                        <el-radio v-for="(item, index) in this.$constVal.modelTypeList" :label="item.value" :key="index">{{item.label}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="模型训练样本路径" prop="train_sampleDir">
                    <el-upload
                            :action="actionUrl"
                            :before-upload="file =>$com.beforeUpload(file,uploadFormat)"
                            :on-remove="uploadRemove"
                            :on-error="() =>$com.warningNotify('上传失败，请检查服务是否正常！')"
                            :on-success="uploadSuccess">
                        <el-button size="small" type="primary">点击上传（.zip文件）</el-button>
                    </el-upload>
                    <el-input v-model="form.train_sampleDir" :disabled="true" clearable
                              placeholder="模型训练样本路径"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit('form')">开始训练</el-button>
                    <el-button>重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </page-template>
</template>

<script>
    import {modelTrainAdd} from '@/api/modelTrain/modelTrain'
    import { validateNecessary, validateLen } from '@/utils/validate'
    export default {
        data() {
            return {
                uploadFormat: ['zip'],
                actionUrl: '/modelTrain/batchUpload',
                form: {
                    modelname: '',
                    modelType: 'cls',
                    modeStep: 'mode_train',
                    train_sampleDir: '',
                    learning_rate: 0.01,
                    max_number_of_steps: '3000',
                    weight_decay: '0.00004',
                    optimizer: 'RMSprop',
                    momentum: 0.9,
                    end_learning_rate: 0.00001,
                    batch_size: 32,
                    lr_policy: 'multistep',
                    model_savepath: '/home../save_validation/'
                },
                rules: {
                    modelname: [validateNecessary(), validateLen(100)],
                    modelType: [validateNecessary('请选择')],
                    modeStep: [validateNecessary('请选择')],
                    // train_sampleDir: [validateNecessary('请上传获取样本路径'), validateNecessary('请上传训练样本文件')],
                    learning_rate: [validateNecessary()],
                    max_number_of_steps: [validateNecessary()],
                    weight_decay: [validateNecessary()],
                    optimizer: [validateNecessary('请选择')],
                    momentum: [validateNecessary()],
                    end_learning_rate: [validateNecessary()],
                    batch_size: [validateNecessary()],
                    lr_policy: [validateNecessary('请选择')],
                    model_savepath: [validateNecessary(), validateLen(100)],
                }
            }
        },
        methods: {
            onSubmit(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        modelTrainAdd(this.form).then(rsp => {
                            if (rsp.status === 200) {
                                this.$com.successNotify(rsp.message)
                                this.formUpset()
                            } else {
                                this.$com.successNotify(rsp.message)
                            }
                        })
                    }
                })
            },
            uploadSuccess(rsp) {
                if (rsp.status === 200) {
                    this.$com.successNotify()
                    this.$vue.set(this.form, 'train_sampleDir', rsp.data)

                } else {
                    this.$com.successNotify(rsp.message)
                }
            },
            uploadRemove() {
                this.$vue.set(this.form, 'train_sampleDir', undefined)
            },
            formUpset() {
                this.form = {
                    modelname: '',
                    modelType: 'cls',
                    modeStep: 'mode_train',
                    train_sampleDir: '',
                    learning_rate: 0.01,
                    max_number_of_steps: '3000',
                    weight_decay: '0.00004',
                    optimizer: 'RMSprop',
                    momentum: 0.9,
                    end_learning_rate: 0.00001,
                    batch_size: 32,
                    lr_policy: 'multistep',
                    model_savepath: '/home../save_validation/'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .createModelCls {
        text-align: left;
        .el-form {
            width: 800px;
            margin-top: 20px;
        }
    }
</style>